<template>
  <div v-if="$store.getters.ready">
    <Header nav="project" :group="$route.params.group" :project="$route.params.project" active="issues" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title border-0 clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${project.group.path}`" class="no-link">{{ project.group.name }}</router-link> &bull; <router-link :to="`/${project.group.path}/${project.path}`" class="no-link">{{ project.name }}</router-link></div> Issues</h5>
            </div>
            <div class="page-title-right">
              <router-link :to="`/${project.group.path}/${project.path}/issues/new`" class="btn btn-primary btn-rounded" role="button"><i class="fal fa-pencil mr-2"></i>New Issue</router-link>
            </div>
          </div>

          <div class="widget-list issuelist">

            <div class="form-group row mb-0">
              <div class="col-md-5 col-lg-6 col-xl-7 mb-2">
                <input type="text" v-model.lazy="search" class="form-control" placeholder="Search or filter issues" maxlength="254">
              </div>
              <div class="col-md-3 col-xl-2 mb-2">
                <div class="input-group">
                  <select v-model="state" class="form-control custom-select">
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                    <option value="">All</option>
                  </select>
                  <div class="input-group-append"><span class="input-group-text"><i class="fal fa-exclamation-circle fa-lg"></i></span></div>
                </div>
              </div>
              <div class="col-md-4 col-lg-3 mb-2">
                <div class="input-group">
                  <select v-model="sort" class="form-control custom-select">
                    <option value="newest">Newest</option>
                    <option value="oldest">Oldest</option>
                    <option value="recentlyupdated">Recently updated</option>
                    <option value="leastrecentlyupdated">Least recently updated</option>
                  </select>
                  <div class="input-group-append"><span class="input-group-text"><i class="fal fa-sort-amount-down fa-lg"></i></span></div>
                </div>
              </div>
            </div>

            <router-link v-for="issue in searchIssues" :key="issue.id" :to="`/${project.group.path}/${project.path}/issues/${issue.number}`">
              <div class="widget-holder">
                <div class="widget-bg">
                  <div class="widget-body">
                    <div class="row">
                      <div class="col-12 d-sm-none">
                        <label-badge v-for="label in issue.labels" :key="label.id" :color="label.color" :text="label.text_color" :label="label.name" class="mr-1 mb-1" />
                      </div>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <h5 class="box-title mb-0">{{ issue.title }}</h5>
                        <div class="d-none d-sm-block"><span class="text-muted mr-2">#{{ issue.number }} opened {{ $datefns.fromNow(issue.created_at) }} by {{ issue.created_by.username }}</span><label-badge v-for="label in issue.labels" :key="label.id" :color="label.color" :text="label.text_color" :label="label.name" class="mr-1 mb-1" /></div>
                        <div class="d-sm-none"><span class="text-muted mr-2">#{{ issue.number }} {{ issue.state === 'open' && issue.comments.length === 0 ? 'opened' : '' }} {{ issue.state === 'open' && issue.comments.length > 0 ? 'updated' : '' }} {{ issue.state === 'closed' ? 'closed' : '' }} {{ issue.state === 'open' && issue.comments.length === 0 ? $datefns.fromNow(issue.created_at) : $datefns.fromNow(issue.updated_at) }}</span><label-badge v-for="label in issue.labels" :key="label.id" :color="label.color" :text="label.text_color" :label="label.name" class="mr-1 mb-1 d-none d-sm-inline-block" /></div>
                      </div>
                      <div class="col-sm-4 col-md-3 col-lg-2 text-right d-none d-sm-block">
                        <h5 class="box-title mb-0 text-uppercase"><span v-if="issue.state === 'closed'">{{ issue.state }}</span><span v-if="issue.comments.length > 0"><i class="fal fa-comments mx-2"></i>{{ issue.comments.length }}</span></h5>
                        <span v-if="issue.state === 'closed' || issue.comments.length > 0" class="text-muted">updated {{ $datefns.fromNow(issue.updated_at) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>

            <div v-if="searchIssues.length === 0" class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">
                  <div class="text-center py-4">
                    <h4 class="my-0"><p><i class="fal fa-exclamation-circle fa-5x text-stroke-5 text-icon-gray"></i></p><p>No issues matched your criteria.</p></h4>
                    <router-link :to="`/${project.group.path}/${project.path}/issues/new`" class="btn btn-primary btn-rounded" role="button"><i class="fal fa-pencil mr-2"></i>New Issue</router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/AccountHeader.vue';
import LabelBadge from '@/components/LabelBadge.vue';
import toast from '@/modules/toast';

export default {
  data() {
    return {
      project: {},
      issues: [],
      search: '',
      state: localStorage.getItem('issues_state') ? localStorage.getItem('issues_state') : 'open',
      sort: localStorage.getItem('issues_sort') ? localStorage.getItem('issues_sort') : 'recentlyupdated',
    };
  },
  watch: {
    state() {
      this.updateIssues();
    },
    sort() {
      this.updateIssues();
    },
  },
  computed: {
    searchIssues() {
      const list = [];
      const tokens = this.search.split(' ');
      for (let i = 0; i < this.issues.length; i += 1) {
        for (let k = 0; k < tokens.length; k += 1) {
          if (tokens[k].startsWith('#')) { tokens[k] = tokens[k].substring(1); }
          if (this.issues[i].number === tokens[k] || this.issues[i].title.toLowerCase().includes(tokens[k].toLowerCase()) || this.issues[i].description.body.toLowerCase().includes(tokens[k].toLowerCase())) {
            list.push(this.issues[i]);
            break;
          }
        }
      }
      return list;
    },
  },
  methods: {
    updateIssues() {
      let orderBy;
      let sort;
      switch (this.sort) {
        case 'leastrecentlyupdated':
          orderBy = 'updated_at';
          sort = 'asc';
          break;
        case 'recentlyupdated':
          orderBy = 'updated_at';
          sort = 'desc';
          break;
        case 'oldest':
          orderBy = 'created_at';
          sort = 'asc';
          break;
        case 'newest':
        default:
          orderBy = 'created_at';
          sort = 'desc';
      }
      localStorage.setItem('issues_state', this.state);
      localStorage.setItem('issues_sort', this.sort);

      this.$api.get(`/projects/${this.project.group.path}%2F${this.project.path}/issues?state=${this.state ? this.state : ''}&order_by=${orderBy}&sort=${sort}`)
        .then((res) => {
          this.issues = res.data;
          this.$store.commit('ready', true);
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  async mounted() {
    try {
      this.project = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)).data;
      this.updateIssues();
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
    LabelBadge,
  },
};
</script>
